import * as React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Productos from "../components/Productos";
import Beneficios from "../components/Beneficios";
import Metrica from "../components/Metrica";
import Servicios from "../components/Servicios";
import Mapa from "../components/Mapa";
import Reviews from "../components/Reviews";
import Footer from "../components/Footer";
import Helmet from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>Mobile-I-Business</title>
        <meta name="title" content="Mobile i Business" />
        <meta http-equiv="title" content="Mobile i Business" />
        <meta name="description" content="automatizacion fuerza ventas" />
        <meta
          http-equiv="DC.Description"
          content="automatizacion fuerza ventas"
        />
        <meta
          name="keywords"
          content="mobilefarma, automatizacion, fuerza ventas, sfa, sales force, automation transfer, pedidos, merchan, merchandiser, merchandising, ffvv, fuerza de ventas, mobileib merchan, mobile i business, repositores, app, gestión, relevamiento, kpi, transfers, materiales pop, relevamiento skus"
        />
        <meta
          http-equiv="DC.Keywords"
          content="mobilefarma, automatizacion, fuerza ventas, sfa, sales force, automation transfer, pedidos, merchan, merchandiser, merchandising, ffvv, fuerza de ventas, mobileib merchan, mobile i business, repositores, app, gestión, relevamiento, kpi, transfers, materiales pop, relevamiento skus"
        />
        <meta name="robots" content="index,follow,all" />
        <meta name="distribution" content="Global" />
        <meta name="copyright" content="Mobile i Business" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta
          http-equiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
      </Helmet>
      <Header />
      <Hero />
      <Productos />
      <Beneficios />
      <Metrica />
      <Servicios />
      <Mapa />
      <Reviews />
      <Footer />
    </main>
  );
};

export default IndexPage;
