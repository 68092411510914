import React from "react";
import { Container, Row, Col, Hidden } from "react-grid-system";
import { useStaticQuery, graphql } from "gatsby";
import { Carousel } from "react-responsive-carousel";
import useLang from "../commons/useLang";
////------------Images------------////
import Comillas from "../../assets/images/icono-comillas.svg";
import BackRight from "../../assets/images/background-testimonios-der.svg";
import BackLeft from "../../assets/images/background-testimonios-izq.svg";
////------------Styles------------////
import "./styles.scss";

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query getReviewsTexts {
      allContentfulReviews {
        nodes {
          review1 {
            nombre
            empresa
            posicion
            texto
          }
          review2 {
            nombre
            empresa
            posicion
            texto
          }
          review3 {
            nombre
            empresa
            posicion
            texto
          }
        }
      }
    }
  `);
  const [currentLang] = useLang();
  const reviewsText = data.allContentfulReviews.nodes[currentLang];
  const generarTextos = (textoASplittear) => {
    return textoASplittear.split("|").map((parrafo) => <p>{parrafo}</p>);
  };
  return (
    <section className="carousel-rev" id="testimonios">
      <Container>
        <Hidden xs sm md>
          <img className="background-img left-img" src={BackLeft} />
          <img className="background-img right-img" src={BackRight} />
        </Hidden>

        <Carousel
          autoPlay
          infiniteLoop
          interval={6000}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          stopOnHover={false}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={40}
        >
          <div className="person-container">
            <div className="person">
              <div className="comillas">
                <img src={Comillas} />
              </div>
              <div className="texts-cotainer">
                {generarTextos(reviewsText.review1.texto)}
                <div className="empresa-container">
                  <h3>
                    {reviewsText.review1.nombre}
                    <Hidden xs sm>
                      {` |`}
                    </Hidden>
                  </h3>
                  <h3 className="empresa">{reviewsText.review1.empresa}</h3>
                </div>
                <Hidden xs sm>
                  <h4>{reviewsText.review1.posicion}</h4>
                </Hidden>
              </div>
            </div>
          </div>
          <div className="person-container">
            <div className="person">
              <div className="comillas">
                <img src={Comillas} />
              </div>
              <div className="texts-cotainer">
                {generarTextos(reviewsText.review2.texto)}
                <div className="empresa-container">
                  <h3>
                    {reviewsText.review2.nombre}
                    <Hidden xs sm>
                      {` |`}
                    </Hidden>
                  </h3>
                  <h3 className="empresa">{reviewsText.review2.empresa}</h3>
                </div>
                <Hidden xs sm>
                  <h4>{reviewsText.review2.posicion}</h4>
                </Hidden>
              </div>
            </div>
          </div>
          <div className="person-container">
            <div className="person">
              <div className="comillas">
                <img src={Comillas} />
              </div>
              <div className="texts-cotainer">
                {generarTextos(reviewsText.review3.texto)}
                <div className="empresa-container">
                  <h3>
                    {reviewsText.review3.nombre}
                    <Hidden xs sm>
                      {` |`}
                    </Hidden>
                  </h3>
                  <h3 className="empresa">{reviewsText.review3.empresa}</h3>
                </div>
                <Hidden xs sm>
                  <h4>{reviewsText.review3.posicion}</h4>
                </Hidden>
              </div>
            </div>
          </div>
        </Carousel>
      </Container>
    </section>
  );
};

export default Reviews;
