import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import useLang from "../commons/useLang";
// -------------------Styles--------------//
import "./styles.scss";

const Metrica = () => {
  const data = useStaticQuery(graphql`
    query getMetrica {
      allContentfulMetricas {
        nodes {
          metrica1
          metrica2
          metrica3
          metrica4
        }
      }
    }
  `);
  const [currentLang] = useLang();
  const metricaTexts = data.allContentfulMetricas.nodes[currentLang];
  return (
    <>
      <section className="metricas-container">
        <Container>
          <Row align="center" justify="center">
            <Col xs={6} md={6} lg={3} xl={3}>
              <div className="metricas">
                <h3>+ {metricaTexts.metrica1[0]}</h3>
                <p>{metricaTexts.metrica1[1]}</p>
              </div>
            </Col>
            <Col xs={6} md={6} lg={3} xl={3}>
              <div className="metricas">
                <h3>+ {metricaTexts.metrica2[0]}</h3>
                <p>{metricaTexts.metrica2[1]}</p>
              </div>
            </Col>
            <Col xs={6} md={6} lg={3} xl={3}>
              <div className="metricas">
                <h3>+ {metricaTexts.metrica3[0]}</h3>
                <p>{metricaTexts.metrica3[1]}</p>
              </div>
            </Col>
            <Col xs={6} md={6} lg={3} xl={3}>
              <div className="metricas">
                <h3>+ {metricaTexts.metrica4[0]}</h3>
                <p>{metricaTexts.metrica4[1]}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Metrica;
