import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PopupModal } from "react-calendly";
import useLang from "../useLang";
import "./styles.scss";

const CalendlyButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query getScheduleMeeting {
      allContentfulCommons {
        nodes {
          scheduleMeeting
        }
      }
    }
  `);

  const [currentLang] = useLang();

  const { scheduleMeeting } = data.allContentfulCommons.nodes[currentLang];

  const [rootTag, setRootTag] = useState(false);

  useEffect(() => setRootTag(document.querySelector("#___gatsby")));

  // abrir popup
  const openModal = () => {
    setIsOpen(true);
  };

  // cerrar pop
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <button onClick={openModal} className="button-calendly">
        {scheduleMeeting}
      </button>
      <PopupModal
        url="https://calendly.com/gciacomercial/alejandro/"
        onModalClose={closeModal}
        open={isOpen}
        rootElement={rootTag}
      />
    </div>
  );
};

export default CalendlyButton;
