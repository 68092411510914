import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import DemoButton from "../commons/DemoButton";
import { Container, Row, Col } from "react-grid-system";
////------------Images/  Gif ---------////
import LogoFarma from "../../assets/images/logo-farma.gif";
import LogoMerchan from "../../assets/images/logo-merchan.gif";
import CheckBlue from "../../assets/images/check.svg";
import CheckOran from "../../assets/images/check-naranja.svg";
import AvatarMerchan from "../../assets/images/pj-merchan.svg";
import AvatarFarma from "../../assets/images/pj-farma.svg";
import AvatarCommerce from "../../assets/images/pj-commerce.svg";
import AvatarStudio from "../../assets/images/pj-studio.svg";
import CloseImg from "../../assets/images/icono-cerrar.svg";
import MiniFarma from "../../assets/images/mini-logo-farma.svg";
import LogoFarmaMini from "../../assets/images/logo-farma-mini.svg";
import MiniMerchan from "../../assets/images/mini-logo-merchan.svg";
///-----LANG-------/////
import useLang from "../commons/useLang";
////------------Styles--------////
import "./styles.scss";

const Popup = (props) => {
  const data = useStaticQuery(graphql`
    query getAllProductsTexts {
      allContentfulProducts {
        nodes {
          popUpCommerceList
          popUpCommerceList2
          popUpCommerceList3
          popUpFarmaList
          popUpFarmaList2
          popUpFarmaList3
          popUpFarmaDescription {
            popUpFarmaDescription
          }
          popUpCommerceDescription {
            popUpCommerceDescription
          }
          popUpMerchanDescription {
            popUpMerchanDescription
          }
          popUpMerchanList
          popUpMerchanList2
          popUpStudioDescription {
            popUpStudioDescription
          }
          popUpStudioList
          popUpbuttonText
        }
      }
    }
  `);
  const [currentLang] = useLang();
  const productsText = data.allContentfulProducts.nodes[currentLang];

  const {
    popUpCommerceList,
    popUpCommerceList2,
    popUpCommerceList3,
    popUpFarmaList,
    popUpFarmaList2,
    popUpFarmaList3,
    popUpFarmaDescription: { popUpFarmaDescription },
    popUpCommerceDescription: { popUpCommerceDescription },
    popUpMerchanDescription: { popUpMerchanDescription },
    popUpMerchanList,
    popUpMerchanList2,
    popUpStudioDescription: { popUpStudioDescription },
    popUpStudioList,
  } = productsText;

  const popupsData = {
    farma: {
      title: <img src={`${LogoFarma}?v=${Math.random().toString(36)}`} />,
      description: popUpFarmaDescription,
      lists: [popUpFarmaList, popUpFarmaList2, popUpFarmaList3],
      color: "blue",
      avatar: <img src={AvatarFarma} />,
    },
    commerce: {
      title: (
        <div>
          <img src={LogoFarmaMini} />
          <h1 className="popup-title"> Portal e-Commerce </h1>
        </div>
      ),
      description: popUpCommerceDescription,
      lists: [popUpCommerceList, popUpCommerceList2, popUpCommerceList3],
      color: "blue",
      avatar: <img src={AvatarCommerce} />,
    },
    merchan: {
      title: <img src={`${LogoMerchan}?v=${Math.random().toString(36)}`} />,
      description: popUpMerchanDescription,
      lists: [popUpMerchanList, popUpMerchanList2],
      color: "orange",
      avatar: <img src={AvatarMerchan} />,
    },
    studio: {
      title: (
        <div>
          <img src={MiniFarma} style={{ marginRight: "1.5rem" }} />
          <img src={MiniMerchan} />
          <h1 className="popup-title">Google Looker Studio</h1>
        </div>
      ),
      description: popUpStudioDescription,
      lists: [popUpStudioList],
      color: "orange",
      avatar: <img src={AvatarStudio} />,
    },
  };
  const productData = popupsData[props.product];

  return productData ? (
    <div className="popup">
      <Container className="popup__wrapper">
        <button className="close-button" onClick={props.onClose}>
          <img src={CloseImg} />
        </button>
        <Row className="popup__row">
          <Col md={12} lg={3} className="popup__left">
            <div className="popup__left-text">
              {productData.title}
              <p>{productData.description}</p>
              <DemoButton styleBoton={productData.color} />
            </div>
          </Col>
          <Col md={12} lg={9} className="popup__right">
            {productData.lists.map((list, index) => (
              <div
                className={`popup__right_list ${
                  index == 0 ? "full-height" : ""
                }`}
              >
                <h3>{list[0]?.trim()}</h3>
                <ul>
                  {list.slice(1).map((item) => {
                    const splitText = item.split("|");
                    return (
                      <li>
                        <img
                          src={
                            productData.color == "blue" ? CheckBlue : CheckOran
                          }
                          className={`check ${productData.color}`}
                        />
                        {splitText[0]?.trim()}
                        {splitText.length > 1 ? (
                          <ul className="sub-list">
                            {splitText.slice(1).map(function (splitItem) {
                              return <li> {splitItem}</li>;
                            })}
                          </ul>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </Col>
          <div className="avatar">{productData.avatar}</div>
        </Row>
      </Container>
    </div>
  ) : null;
};

export default Popup;
