import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import useLang from "../commons/useLang";
// -------------------IMAGES------------------//
import Iso from "../../assets/images/logo-iso9001.svg";
import Cessi from "../../assets/images/logo-cessi.png";
import Multinavegador from "../../assets/images/logos-navegadores.png";
import Android from "../../assets/images/android.png";
import Ios from "../../assets/images/icono-ios.png";
////------------PDF----------////
import Isopolitica from "../../assets/images/politicas-calidad.pdf";
// -------------------Styles--------------//
import "./styles.scss";

const Servicios = () => {
  const data = useStaticQuery(graphql`
    query getServiciosTexts {
      allContentfulServicios {
        nodes {
          title
          iso
          partners
          software
          multinavegador
        }
      }
    }
  `);
  const [currentLang] = useLang();
  const serviciosText = data.allContentfulServicios.nodes[currentLang];
  const camara = (serviciosText.software[2] || "").split("|");
  return (
    <>
      <Container id="calidad">
        <Row>
          <Col>
            <h2>{serviciosText.title}</h2>
          </Col>
        </Row>
        <div className="servicios-container">
          <Row>
            <Col xs={12} xl={5} offset={{ lg: 0, xl: 1 }}>
              <img
                src={Iso}
                style={{ width: "200px" }}
                className="img__bigger"
              />
              <h3>{serviciosText.iso[1]}</h3>
              <p>{serviciosText.iso[2]}</p>
              <a className={Isopolitica} href={Isopolitica} download>
                <p className="download">{serviciosText.iso[3]}</p>
              </a>
            </Col>
            <Col xs={12} xl={5}>
              <img
                src={Cessi}
                style={{ width: "200px" }}
                className="img__bigger"
              />
              <h3>{serviciosText.software[1]}</h3>
              <p>
                {camara[0]}
                <a
                  className="download"
                  href="https://www.cessi.org.ar/home"
                  target="_blank"
                >
                  {camara[1]}
                </a>
                {camara[2]}
              </p>
            </Col>
            <Col xs={12} xl={5} offset={{ lg: 0, xl: 1 }}>
              <div className="servicios-low-row">
                <div className="partners">
                  <img src={Android} />
                  <img src={Ios} />
                </div>
                <h3>{serviciosText.partners[1]}</h3>
                <p>{serviciosText.partners[2]}</p>
              </div>
            </Col>
            <Col xs={12} xl={5}>
              <div className="servicios-low-row">
                <img src={Multinavegador} />
                <h3>{serviciosText.multinavegador[1]}</h3>
                <p>{serviciosText.multinavegador[2]}</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Servicios;
