import React from "react";
import { Hidden } from "react-grid-system";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// ---------------------Imgs--------------------------//
import Elea from "../../../assets/images/logo-elea.png";
import Nestle from "../../../assets/images/logo-nestle.png";
import Pfizer from "../../../assets/images/pfizer-upjohn.png";
import Casasco from "../../../assets/images/logo-casasco.png";
import Kellerhof from "../../../assets/images/logo-keller.png";
import Panalab from "../../../assets/images/logo-panalab.png";
import Colgate from "../../../assets/images/logo-colgate.png";
import Beiersdorf from "../../../assets/images/logo-beiersdorf.png";
import Softys from "../../../assets/images/logo-softys.png";
import Ena from "../../../assets/images/logo-ena.png";
import Andromaco from "../../../assets/images/logo-andromaco.png";
// -------------------Styles-----------------//
import "./styles.scss";

const Slider = () => {
  const images = [
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
    <img src={Ena} />,
    <img src={Softys} />,
    <img src={Beiersdorf} />,
    <img src={Panalab} />,
    <img src={Kellerhof} />,
    <img src={Colgate} />,
    <img src={Andromaco} />,
    <img src={Elea} />,
    <img src={Nestle} />,
    <img src={Pfizer} />,
    <img src={Casasco} />,
  ];

  const commonProps = {
    autoPlay: true,
    interval: 2500,
    transitionTime: 3000,
    centerMode: true,
    infiniteLoop: true,
    selectedItem: 10,
    showStatus: false,
    showThumbs: false,
    showIndicators: false,
    showArrows: false,
    stopOnHover: false,
    swipeable: false,
  };

  return (
    <div className="hero-clientes">
      <Hidden xs sm md>
        <Carousel {...commonProps} centerSlidePercentage={15}>
          {images.map((img) => img)}
        </Carousel>
      </Hidden>
      <Hidden lg xl xxl>
        <Carousel {...commonProps} centerSlidePercentage={60}>
          {images.map((img) => img)}
        </Carousel>
      </Hidden>
    </div>
  );
};

export default Slider;
