import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col, Hidden } from "react-grid-system";
////--------Components---------/////
import DemoButton from "../commons/DemoButton";
import CalendlyButton from "../commons/CalendlyButton/CalendlyButton";
import Slider from "../commons/Slider";
import useLang from "../commons/useLang";
////--------Images----------////
import HeroImg from "../../assets/images/hero-image.png";
////--------Styles-------////
import "./styles.scss";

const Hero = () => {
  const data = useStaticQuery(graphql`
    query getHeroText {
      allContentfulHero {
        nodes {
          title
          subtitle
        }
      }
    }
  `);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);
  const [currentLang] = useLang();
  const heroTexts = data.allContentfulHero.nodes[currentLang];
  return (
    <>
      <Container className="heroContainer" id="hero">
        <Row>
          <Col className="hero-text__container">
            <div className="text-container">
              <h1>{heroTexts.title}</h1>
              <p>{heroTexts.subtitle}</p>
              <div className="button">
                <DemoButton />
                <CalendlyButton />
              </div>
            </div>
          </Col>

          <Hidden xs sm md>
            <Col>
              <img src={HeroImg} className="heroBack" />
            </Col>
          </Hidden>
        </Row>
      </Container>
      <Slider />
    </>
  );
};

export default Hero;
