import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import useLang from "../commons/useLang";
// -----------------------Images--------------------//
import Reloj from "../../assets/images/beneficios/icono-horas.svg";
import Mejoras from "../../assets/images/beneficios/icono-mejoras.svg";
import Hosting from "../../assets/images/beneficios/icono-hosting.svg";
import Soporte from "../../assets/images/beneficios/icono-soporte.svg";
import Msn from "../../assets/images/beneficios/icono-personas.svg";
import Garantia from "../../assets/images/beneficios/icono-garantia.svg";
// -------------------Styles--------------//
import "./styles.scss";

const Beneficios = () => {
  const data = useStaticQuery(graphql`
    query getBeneficios {
      allContentfulBeneficios {
        nodes {
          desarrolloList
          equipoList
          garantiaList
          title
          innovacionList
          soporteList
          hostingList
        }
      }
    }
  `);
  const [currentLang] = useLang();
  const beneficioTexts = data.allContentfulBeneficios.nodes[currentLang];
  return (
    <>
      <Container className="beneficios-container" id="beneficios">
        <h2>{beneficioTexts.title}</h2>
        <Row>
          <Col xs={12} md={4}>
            <img className="imgs" src={Reloj} />
            <div className="textos">
              <h3>{beneficioTexts.desarrolloList[1]}</h3>
              <p>{beneficioTexts.desarrolloList[2]}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <img className="imgs" src={Mejoras} />
            <div className="textos">
              <h3>{beneficioTexts.innovacionList[1]}</h3>
              <p>{beneficioTexts.innovacionList[2]}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <img className="imgs" src={Hosting} />
            <div className="textos">
              <h3>{beneficioTexts.hostingList[1]}</h3>
              <p>{beneficioTexts.hostingList[2]}</p>
            </div>
          </Col>
        </Row>
        <Row className="beneficio-list">
          <Col xs={12} md={4}>
            <img className="imgs" src={Soporte} />
            <div className="textos">
              <h3>{beneficioTexts.soporteList[1]}</h3>
              <p>{beneficioTexts.soporteList[2]}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <img className="imgs" src={Msn} />
            <div className="textos">
              <h3>{beneficioTexts.equipoList[1]}</h3>
              <p>{beneficioTexts.equipoList[2]}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <img className="imgs" src={Garantia} />
            <div className="textos">
              <h3>{beneficioTexts.garantiaList[1]}</h3>
              <p>{beneficioTexts.garantiaList[2]}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Beneficios;
