import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import DemoButton from "../commons/DemoButton";
import useLang from "../commons/useLang";
////-------------------Images------------/////
import MapaSud from "../../assets/images/mapa-sudamerica.svg";
////---------------Styles------------------////
import "./styles.scss";

const Mapa = () => {
  const data = useStaticQuery(graphql`
    query getMapaTexts {
      allContentfulMapa {
        nodes {
          title
          subtitle
        }
      }
    }
  `);
  const [currentLang] = useLang();
  const mapaTexts = data.allContentfulMapa.nodes[currentLang];
  return (
    <>
      <section className="mapa-container">
        <Container>
          <Row>
            <Col className="texts-container" xs={12} lg={6}>
              <h1>{mapaTexts.title}</h1>
              <p>{mapaTexts.subtitle}</p>
              <DemoButton />
            </Col>
            <Col xs={12} lg={6}>
              <img className="mapa-sud" src={MapaSud} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Mapa;
