import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col, Hidden } from "react-grid-system";
import Popup from "./Popup";
import { Carousel } from "react-responsive-carousel";
import useLang from "../commons/useLang";
////-----------------Images----------------////
import Check from "../../assets/images/check.svg";
import CheckOrange from "../../assets/images/check-naranja.svg";
import LogoFarmaBlack from "../../assets/images/logo-farma-black.svg";
import LogoMerchanBlack from "../../assets/images/logo-merchan-black.svg";
////-----------------Styles----------------////
import "./styles.scss";

// products: farma, merchan, commerce, studio

const Productos = () => {
  const [popupProduct, setPopupProduct] = useState("");
  const data = useStaticQuery(graphql`
    query getProductText {
      allContentfulProducts {
        nodes {
          popUpbuttonText
          title
          title
          cardCommerce {
            list
            title
          }
          cardFarma {
            list
          }
          cardMerchan {
            list
          }
          cardStudio {
            title
            list
          }
        }
      }
    }
  `);
  const mapToItem = function (color) {
    return function (item) {
      return (
        <li>
          <img src={color == "orange" ? CheckOrange : Check} />
          {item}
        </li>
      );
    };
  };

  const handleOpenPopup = (product) => {
    document.body.style.overflow = "hidden";
    setPopupProduct(product);
  };
  const handleClosePopup = () => {
    document.body.style.overflow = "visible";
    setPopupProduct("");
  };
  const Button = function (props) {
    return (
      <button
        onClick={() => handleOpenPopup(props.product)}
        className={`explore-button ${props.colorBoton}`}
      >
        {productsText.popUpbuttonText}
      </button>
    );
  };
  const [currentLang] = useLang();
  const productsText = data.allContentfulProducts.nodes[currentLang];
  return (
    <>
      <Popup product={popupProduct} onClose={() => handleClosePopup()} />
      <Container className="products-container" id="productos">
        <Row justify="center">
          <Col>
            <h2>{productsText.title}</h2>
          </Col>
        </Row>
        <Hidden xs sm md>
          <Row>
            <Col className="cards-flex">
              {/* <h3 className="farma-title">{productsText.cardFarma.title}</h3> */}
              <img className="title" src={LogoFarmaBlack} />
              <ul>{productsText.cardFarma.list.map(mapToItem("blue"))}</ul>
              <Button colorBoton="blue" product="farma" />
            </Col>
            <Col className="cards-flex">
              <h3>{productsText.cardCommerce.title}</h3>
              <ul>{productsText.cardCommerce.list.map(mapToItem("blue"))}</ul>
              <Button colorBoton="blue-hollow" product="commerce" />
            </Col>
            <Col className="cards-flex">
              {/* <h3 className="merchan-title">
                {productsText.cardMerchan.title}
              </h3> */}
              <img className="title" src={LogoMerchanBlack} />
              <ul>{productsText.cardMerchan.list.map(mapToItem("orange"))}</ul>
              <Button colorBoton="orange" product="merchan" />
            </Col>
            <Col className="cards-flex">
              <h3>{productsText.cardStudio.title}</h3>
              <ul>{productsText.cardStudio.list.map(mapToItem("orange"))}</ul>
              <Button colorBoton="orange-hollow" product="studio" />
            </Col>
          </Row>
        </Hidden>
        <Hidden lg xl xxl>
          <Carousel
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            stopOnHover={false}
            preventMovementUntilSwipeScrollTolerance
            swipeScrollTolerance={40}
          >
            <div className="shadow-container">
              <div className="cards-flex-mobile">
                <img className="title" src={LogoFarmaBlack} />
                <ul>{productsText.cardFarma.list.map(mapToItem("blue"))}</ul>
                <Button colorBoton="blue" product="farma" />
              </div>
            </div>
            <div className="shadow-container">
              <div className="cards-flex-mobile">
                <h3>{productsText.cardCommerce.title}</h3>
                <ul>{productsText.cardCommerce.list.map(mapToItem("blue"))}</ul>
                <Button colorBoton="blue-hollow" product="commerce" />
              </div>
            </div>
            <div className="shadow-container">
              <div className="cards-flex-mobile">
                <img className="title" src={LogoMerchanBlack} />
                <ul>
                  {productsText.cardMerchan.list.map(mapToItem("orange"))}
                </ul>
                <Button colorBoton="orange" product="merchan" />
              </div>
            </div>
            <div className="shadow-container">
              <div className="cards-flex-mobile">
                <h3>{productsText.cardStudio.title}</h3>
                <ul>{productsText.cardStudio.list.map(mapToItem("orange"))}</ul>
                <Button colorBoton="orange-hollow" product="studio" />
              </div>
            </div>
          </Carousel>
        </Hidden>
      </Container>
    </>
  );
};

export default Productos;
